import * as React from "react";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: -8,
    marginBottom: -8,
  },
  chip: { margin: 4 },
});

const PlanField = ({ record }) => {
  const classes = useStyles();
  if (!record) return null;
  const active =
    record?.subscriptions?.find((i) => i?.status == "active") || null;
  const last = record?.subscriptions ? record?.subscriptions[0] : null;
  const subscription = active ? active : last;
  return subscription ? (
    <span className={classes.main}>
      {subscription?.plan ? (
        <Chip
          size="small"
          className={classes.chip}
          label={subscription.plan?.name}
        />
      ) : null}
    </span>
  ) : null;
};

export default PlanField;
