import {
  AutocompleteInput,
  Datagrid,
  DateInput,
  Filter,
  FunctionField,
  List,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  downloadCSV,
  useNotify,
} from "react-admin";
import { cloneElement } from "react";
import { TopToolbar, CreateButton, ExportButton } from "react-admin";
import jsonExport from "jsonexport/dist";
import { checkPermissions } from "../../../utils/validations";

const CustomFilters = (props) => (
  <Filter {...props}>
    <TextInput source="search" label="ra.action.search" alwaysOn />
    <ReferenceInput
      label="Taller"
      source="workshop_id"
      reference="workshop"
      sort={{ field: "created_at", order: "DESC" }}
      perPage={200}
    >
      <AutocompleteInput source="name" optionValue="id" />
    </ReferenceInput>
    <SelectInput
      source="status"
      choices={[
        { id: "activo", name: "Activo" },
        { id: "cancelado", name: "Cancelado" },
      ]}
      label="Estado"
    />
    <DateInput source="date" label="Fecha" />
  </Filter>
);

const Actions = ({
  label = "Nuevo",
  resource,
  filter,
  exportButton = false,
  createButton = true,
  maxResults = 10000,
  ...props
}) => {
  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };
  const notify = useNotify();
  const exporter = (values) => {
    let resp = [];
    if (values && values.length > 0) {
      values.forEach((item) => {
        const details = JSON.parse(item?.details);
        let obj = {};

        obj.Taller = removeAccents(item?.workshopdate?.workshop?.name);
        obj.Nombre = removeAccents(item?.name);
        obj.Apellido = removeAccents(item?.lastname);
        obj.Email = item.email;
        obj.Telefono =
          details && details?.length > 0 && details[0]?.phone
            ? details[0]?.phone
            : "";
        obj.DNI =
          details && details?.length > 0 && details[0]?.dni
            ? details[0]?.dni
            : "";
        obj.Acompañante =
          details && details?.length == 2 && typeof details[1] != "undefined"
            ? `${details[1]?.name || ""} ${details[1]?.lastname}`
            : "";
        obj['Acompañante DNI']=
          details && details?.length == 2 && typeof details[1] != "undefined"
            ? `${details[1]?.dni || ""}`
            : "";
        if (details && details?.length == 2) {
        }
        // obj.Fecha = item?.workshopdate?.date
        //   ? new Date(item?.workshopdate?.date).toLocaleString()
        //   : "";

        if (props?.filterValues?.status) {
          if (props?.filterValues?.status == item?.status) {
            resp.push(obj);
          }
        } else {
          resp.push(obj);
        }
      });
    }

    if (resp && resp?.length > 0) {
      let respErr = "";
      let respCsv;
      jsonExport(resp, { rowDelimiter: ";" }, function (err, csv) {
        if (err) respErr = err;
        respCsv = csv;
      });
      if (respCsv && respErr === "") {
        downloadCSV(
          respCsv,
          `suscripticon_talleres_${new Date().toLocaleDateString()}`
        );
      } else {
        notify("Error al convertir a csv", "warning");
      }
    }
  };
  return (
    <TopToolbar>
      {props?.filters && cloneElement(props?.filters, { context: "button" })}
      {exportButton && (
        <ExportButton
          resource={resource}
          filter={filter}
          exporter={exporter}
          maxResults={maxResults}
        />
      )}
      {createButton ? (
        <CreateButton
          variant="contained"
          size="medium"
          style={{ marginLeft: "8px" }}
          label={label}
          translate={label}
        />
      ) : null}
    </TopToolbar>
  );
};
export const SubscriptionWorkshopdateList = ({ ...props }) => {
  const canEdit = checkPermissions(
    props?.permissions,
    "edit-subscription-workshopdate"
  );
  const canCreate = checkPermissions(
    props?.permissions,
    "create-subscription-workshopdate"
  );

  const getPhone = (v) => {
    const details = JSON.parse(v?.details);
    if (Array.isArray(details) && details?.length > 0) {
      return details[0]?.phone || "";
    }
    return "";
  };

  return (
    <List
      sort={{ field: "created_at", order: "DESC" }}
      bulkActionButtons={false}
      title=" "
      actions={<Actions exportButton={true} createButton={canCreate} />}
      filters={<CustomFilters />}
      {...props}
    >
      <Datagrid rowClick={canEdit ? "edit" : "show"}>
        <TextField source="workshopdate.workshop.name" label="Taller" />
        <FunctionField
          label="Nombre"
          render={(i) =>
            `${i?.name ? i.name : ""} ${i?.lastname ? i?.lastname : ""}`
          }
        />
        <TextField source="email" />
        <TextField source="status" />
        <FunctionField label="Teléfono" render={(v) => getPhone(v)} />
      </Datagrid>
    </List>
  );
};
