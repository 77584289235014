import React, { useState, useEffect } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { TextInput, Button, useRecordContext } from "react-admin";
import { Typography } from "@material-ui/core";
import { useField } from "react-final-form";
import IconContentAdd from "@material-ui/icons/Add";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import ImageDefault from "../../../assets/img/note-default.png";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles({
  container: {
    "& img": {
      width: "100%",
      height: "250px",
      objectFit: "cover",
    },
    "& img.small": {
      maxWidth: "250px",
    },
    "& button:hover + img": {
      filter: "blur(3px) brightness(.9)",
    },
    marginBottom: "16px",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  hidden: {
    display: "none",
  },
  photographer: {
    display: "flex",
    justifyContent: "flex-end",
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "12px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "400",
    letterSpacing: "0.03333em",
    marginBottom: "10px",
  },
  deleteButton: {
    lineHeight: 1,
    color: "rgb(31, 58, 79)",
    background: "rgba(255, 255, 255, 0.8)",
    boxShadow: "1px 1px 3px 1px #0000002b",
    borderRadius: "50px",
    fontSize: "12px",
    minWidth: "28px",
    width: "28px",
    height: "28px",
    "& .MuiSvgIcon-root": {
      width: "18px",
      height: "18px",
    },
    "&:hover": {
      background: "rgba(255, 255, 255, 0.8)",
    },
  },
});

export const ImageInputBenefit = ({
  source = "featured_image",
  label = "Imagen de beneficio",
  multiple = false,
  galleryClasses = "",
}) => {
  const [image, setImage] = useState("");
  const classes = useStyles();
  const record = useRecordContext();
  const [customEvent, setCustomEvent] = useState();
  const { input: input_image } = useField(source);

  useEffect(() => {
    if (record && record[`${source}`]) {
      setImage(record[`${source}`]);
    }
  }, [record]);

  const handleCallback = (event) => {
    const { editorId, image } = event.detail;
    if (editorId === source) {
      if (Array.isArray(image)) {
        const newImages = image.map((i) => i.url);
        setImage(newImages);
        input_image.onChange(newImages);
      } else {
        setImage(image.url);
        input_image.onChange(image.url);
      }
    }
  };

  const handleOnChange = (e) => {
    setImage(e.target.value);
  };

  const handleClick = () => {
    window.addEventListener(
      "selected-image-imagepicker-benefits",
      handleCallback
    );
    window.dispatchEvent(customEvent);
  };

  const handleDelete = () => {
    setImage(null);
    input_image.onChange(null);
  };

  useEffect(() => {
    setCustomEvent(
      new CustomEvent("open-imagepicker-benefits", {
        detail: {
          editor: source,
          modemultiselect: multiple,
        },
        bubbles: false,
        cancelable: true,
        composed: false,
      })
    );
    return () => {
      setCustomEvent(null);
    };
  }, []);

  return (
    <>
      <div className="d-flex">
        <Typography style={{ fontSize: "16px" }} gutterBottom>
          {label}
        </Typography>
      </div>
      <div className={classes.container} style={{ position: "relative" }}>
        {image && image !== "" ? (
          <Box
            style={{
              position: "absolute",
              top: "12px",
              padding: "0 12px",
              boxSizing: "border-box",
              zIndex: 2,
              display: "flex",
              justifyContent: "space-between",
              width: multiple ? "250px" : "100%",
            }}
          >
            <Button
              onClick={handleClick}
              label={"Actualizar"}
              style={{
                color: "rgb(31, 58, 79)",
                background: "rgba(255, 255, 255, 0.8)",
                boxShadow: "1px 1px 3px 1px #0000002b",
              }}
            >
              <AutorenewIcon />
            </Button>
            <Button onClick={handleDelete} className={classes.deleteButton}>
              <DeleteIcon />
            </Button>
          </Box>
        ) : (
          <Button
            onClick={handleClick}
            label={"Agregar"}
            style={{
              position: "absolute",
              right: "12px",
              top: "12px",
              zIndex: 2,
              color: "rgb(31, 58, 79)",
              background: "rgba(255, 255, 255, 0.8)",
              boxShadow: "1px 1px 3px 1px #0000002b",
            }}
          >
            <IconContentAdd />
          </Button>
        )}
        {Array.isArray(image) ? (
          <ul
            className={galleryClasses}
            style={{ padding: 0, listStyle: "none", marginTop: "50px" }}
          >
            {image?.map((i, key) => (
              <li key={key} style={{ marginBottom: "10px" }}>
                <img src={i ? i : ImageDefault} alt="" className={"small"} />
              </li>
            ))}
          </ul>
        ) : (
          <img
            src={image ? image : ImageDefault}
            alt=""
            className={classes.img}
          />
        )}
      </div>
      <div className={classes.hidden}>
        <TextInput
          id={`${source}`}
          source={`${source}`}
          label="Imagen principal"
          onChange={handleOnChange}
          value={image}
          fullWidth
        />
      </div>
    </>
  );
};
