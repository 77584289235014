import { useState } from "react";
import {
  Create,
  fetchStart,
  fetchEnd,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  required,
  Toolbar,
  SaveButton,
  useRefresh,
} from "react-admin";
import { useDispatch } from "react-redux";
import { Typography, makeStyles } from "@material-ui/core";
import { apiUrl, httpClient } from "../../utils/utilsFetch";
import { CircularProgress } from "@material-ui/core";
import { useTranslate } from "ra-core"

const useStyles = makeStyles({
  width500: {
    width: "500px",
  },
});

export const ContactFormCreate = ({ ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const translate = useTranslate();

  const tipos = [
    { id: "problema", name: `${translate("components.problem")}` },
    { id: "sugerencia", name: `${translate("components.improvement_suggestion")}`},
  ];

  const prioridad = [
    { id: "urgente", name: `${translate("components.priorities.urgent")}` },
    { id: "alta", name: `${translate("components.priorities.high")}` },
    { id: "media", name: `${translate("components.priorities.medium")}` },
    { id: "baja", name: `${translate("components.priorities.low")}` },
  ];

  const CustomToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton
        label={translate("ra.action.send")}
        disabled={loading}
        icon={loading ? <CircularProgress size={18} thickness={2} /> : <></>}
        onSave={sendForm}
      />
    </Toolbar>
  );

  const sendForm = async (data) => {
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator

    httpClient(apiUrl + "/technical-assistance", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status === 200) {
          notify("ra.notification.ticket_created");
          refresh();
        } else {
          notify("ra.notification.http_error", { type: "warning" });
        }
      })
      .catch((e) => {
        notify("ra.notification.http_error", { type: "warning" });
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  };

  return (
    <Create {...props} title=" ">
      <SimpleForm toolbar={<CustomToolbar />}>
        <Typography variant="h6">{translate("components.technical_support")}</Typography>
        <SelectInput
          choices={tipos}
          source="type"
          label={translate("components.type")}
          validate={requiredValidate}
        ></SelectInput>
        <SelectInput
          choices={prioridad}
          source="priority"
          label={translate("components.priority")}
          validate={requiredValidate}
        ></SelectInput>
        <Typography className={classes.width500} style={{ marginBottom: ".5em" }}>
         {translate("components.description_problem_text")}
        </Typography>
        <Typography className={classes.width500} style={{ marginBottom: ".5em" }}>
         {translate("components.create_ticket_text")}
        </Typography>
        <TextInput
          className={classes.width500}
          label={translate("components.description")}
          source="description"
          multiline
          minRows={5}
          validate={requiredValidate}
        />
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];
